<template>
  <div>
    <v-container class="mt-n4">
      <v-row class="align-center justify-center">
        <v-btn outlined text @click="setToday" class="mr-2">Heute</v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="prev()"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>früher</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="next()"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>später</span>
        </v-tooltip>

        <v-menu
          v-model="dateMenu"
          offset-y
          :nudge-left="40"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined text v-bind="attrs" v-on="on" class="mr-4">
              {{ formatDate(focus, true) }}</v-btn
            >
          </template>

          <v-date-picker
            color="primary"
            v-model="focus"
            locale="de-CH"
            first-day-of-week="1"
            show-week
            :locale-first-day-of-year="4"
            @input="dateMenu = false"
          >
            <v-spacer /><v-btn
              text
              @click="
                setToday();
                dateMenu = false;
              "
              >heute</v-btn
            >
          </v-date-picker>
        </v-menu>

        <v-autocomplete
          v-if="rooms.length > 0"
          ref="roomInput"
          autofocus
          auto-select-first
          menu-props="closeOnContentClick"
          v-model="selectedRooms"
          :items="rooms"
          chips
          deletable-chips
          label="Zimmer"
          item-text="description"
          item-value="id"
          multiple
          single-line
          return-object
          clearable
          :search-input.sync="roomSearchInput"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click:close="data.parent.selectItem(data.item)"
            >
              {{ data.item.code }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.code }} </v-list-item-title>
              <v-list-item-subtitle
                >{{ data.item.description.replace(data.item.code + " - ", "") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-row>
    </v-container>
    <v-alert
      v-if="selectedRooms.length == 0"
      type="info"
      text
      prominent
      border="left"
      ><strong>Bitte mindestens einen Raum wählen!</strong><br />
      Weitere Informationen zur Raumbelegung und -reservation findest du in der
      Hilfe (Fragezeichen oben rechts)!</v-alert
    >
    <v-card :loading="loading" v-if="selectedRooms.length > 0">
      <v-system-bar>{{
        weekView
          ? formatDate(start, true) + " – " + formatDate(end, true)
          : formatDate(focus, true)
      }}</v-system-bar>
      <v-calendar
        locale="de-CH"
        ref="calendar"
        v-model="focus"
        color="primary"
        :type="weekView ? 'week' : 'category'"
        category-show-all
        category-hide-dynamic
        :categories="selectedRooms"
        :category-text="getCategoryText"
        :events="eventsFiltered"
        :weekdays="'1,2,3,4,5'"
        first-interval="7:00"
        interval-count="13"
        category-days="1"
        @click:event="clickEvent"
      >
        <template v-slot:category="{ category }"
          ><div class="text-center">
            {{ category.description }}
          </div>
        </template>
        <template
          v-slot:event="{ event, eventParsed, day, eventSummary, timeSummary }"
        >
          <div class="px-1">
            <template v-if="event.reservable"
              ><v-row no-gutters
                ><v-col>{{ timeSummary() }}</v-col>

                <v-col cols="auto"
                  ><v-icon color="white" small>mdi-plus</v-icon>
                </v-col></v-row
              ></template
            >
            <template v-else>
              <v-row no-gutters
                ><v-col>
                  <span>
                    <component
                      v-if="
                        eventParsed.start.date == day.date &&
                        eventParsed.end.date == day.date
                      "
                      :is="{ render: eventSummary }"
                    ></component>
                    <template v-else>
                      <strong>{{ eventParsed.input.name }}</strong
                      ><br />
                      <span v-if="weekView"
                        >{{ formatDayOfWeek(event.start) }}
                        {{ event.startTime }} -
                        {{ formatDayOfWeek(event.end) }}
                        {{ event.endTime }}</span
                      >
                      <span
                        v-if="
                          !weekView &&
                          eventParsed.start.date == day.date &&
                          eventParsed.end.date != day.date
                        "
                        >ab {{ eventParsed.start.time }} (bis
                        {{ formatDate(eventParsed.end.date, true) }},
                        {{ event.endTime }})
                      </span>
                      <span
                        v-if="
                          !weekView &&
                          eventParsed.start.date != day.date &&
                          eventParsed.end.date == day.date
                        "
                        >bis {{ eventParsed.end.time }}
                        (ab
                        {{ formatDate(eventParsed.start.date, true) }},
                        {{ event.startTime }})</span
                      >
                      <span
                        v-if="
                          !weekView &&
                          eventParsed.start.date != day.date &&
                          eventParsed.end.date != day.date
                        "
                        >{{ formatDate(eventParsed.start.date, true) }} bis
                        {{ formatDate(eventParsed.end.date, true) }}
                      </span>
                    </template>
                  </span>
                </v-col>

                <v-col cols="auto" v-if="event.deletable"
                  ><v-icon color="white" small>mdi-trash-can</v-icon>
                </v-col></v-row
              >
            </template>
          </div>
        </template>
      </v-calendar>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  addDays,
  findMonday,
  formatDate,
  formatDayOfWeek,
  today,
} from "common/utils/date.js";
import { personCode } from "common/utils/people.js";
export default defineComponent({
  name: "Reservations",
  data() {
    return {
      focus: "",
      days: [],
      dateMenu: false,
      loading: false,
      search: "",
      events: [],
      reservations: [],
      rooms: [],
      selectedRooms: [],
      room: {},
      roomSearchInput: null,
    };
  },
  watch: {
    focus() {
      this.fetchReservations();
      if (this.$_hasRole("sok")) {
        return;
      }
      window.localStorage.setItem("roomReservationDate", this.focus);
    },
    selectedRooms() {
      if (this.$_hasRole("sok")) {
        return;
      }
      const roomCodes = this.selectedRooms.map((el) => el.code);
      window.localStorage.setItem(
        "roomReservationRooms",
        JSON.stringify(roomCodes)
      );
      this.$router
        .replace({
          query: { rooms: roomCodes },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      this.fetchReservations();
      this.roomSearchInput = null;
      window.setTimeout(() => this.$refs.roomInput.$refs.input.focus());
    },
  },
  computed: {
    eventsFiltered() {
      return this.events.filter((el) =>
        this.selectedRooms.map((el) => el.description).includes(el.category)
      );
    },
    weekView() {
      return !this.$vuetify.breakpoint.mobile && this.selectedRooms.length <= 1;
    },
  },
  methods: {
    formatDayOfWeek,
    addType(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          this.selectedRooms.push(room);
        }
      }
    },
    setType(typeId) {
      this.selectedRooms = [];
      this.addType(typeId);
      this.roomDialog = false;
    },
    allAdded(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          return false;
        }
      }
      return true;
    },
    someMissing(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        if (this.selectedRooms.some((el) => el.id == room.id)) {
          return false;
        }
      }
      return true;
    },
    removeType(typeId) {
      for (const room of this.getRoomsByType(typeId)) {
        const index = this.selectedRooms.findIndex((el) => el.id == room.id);
        if (index >= 0) {
          this.selectedRooms.splice(index, 1);
        }
      }
    },
    getRoomsByType(typeId) {
      return this.rooms.filter((el) => el.type.id == typeId);
    },
    formatDate,
    getCategoryText(cat) {
      return cat.description;
    },
    setToday() {
      this.focus = today();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async fetchReservations() {
      if (!this.focus) {
        return;
      }

      if (this.weekView) {
        this.start = findMonday(this.focus);
        this.end = addDays(this.start, 4);

        this.days = [];
        let day = this.start;
        while (day <= this.end) {
          this.days.push(day);
          day = addDays(day, 1);
        }
      } else {
        this.days = [this.focus];
        this.start = this.focus;
        this.end = this.focus;
      }
      const now = new Date();
      this.loading = true;

      const events = [];
      const reservations = [];
      for (const room of this.selectedRooms) {
        const roomReservations = await this.apiList({
          resource: "register/reservation",
          query: `startDate=${this.start}&endDate=${this.end}&room=${room.id}`,
        });

        for (const item of roomReservations) {
          reservations.push(item);
          const eventStart = item.startTime
            ? new Date(item.startDate + "T" + item.startTime)
            : new Date(item.startDate);
          const eventEnd = item.endTime
            ? new Date(item.endDate + "T" + item.endTime)
            : new Date(item.endDate);
          item.start = eventStart;
          item.end = eventEnd;
          const mine = this.$_isPerson(item.reservedFor);
          events.push({
            id: item.id,
            name: item.description
              ? item.description + " (" + personCode(item.reservedFor) + ")"
              : personCode(item.reservedFor),
            category: item.room.description,
            room: item.room,
            start: eventStart,
            end: eventEnd,
            startTime: item.startTime,
            endTime: item.endTime,
            timed: true,
            color: mine ? "primary" : "grey",
            deletable: mine && now < eventEnd.getTime(),
          });
        }

        const lessons = await this.apiList({
          resource: "register/lesson",
          query: `startDate=${this.start}&endDate=${this.end}&room=${room.id}`,
        });
        for (const item of lessons) {
          const eventStart = new Date(
            item.date + "T" + item.period.startTime // + '+01:00'
          );
          const eventEnd = new Date(
            item.date + "T" + item.period.endTime // + '+01:00'
          );

          events.push({
            name:
              item.course.code +
              " (" +
              item.course.teachers.map((el) => el.code).join(", ") +
              ")",
            category: room.description,
            start: eventStart,
            end: eventEnd,
            timed: true,
            color: "info",
          });
        }

        const periods = await this.apiList({
          resource: "common/period",
          query: "room=" + room.id,
        });
        for (const day of this.days) {
          for (const period of periods) {
            const periodStart = new Date(day + "T" + period.startTime);
            const periodEnd = new Date(day + "T" + period.endTime);
            if (
              reservations.some(
                (el) =>
                  el.room.id == room.id &&
                  ((periodEnd.getTime() >= el.start.getTime() &&
                    periodEnd.getTime() <= el.end.getTime()) ||
                    (periodStart.getTime() >= el.start.getTime() &&
                      periodStart.getTime() <= el.end.getTime()) ||
                    (el.end.getTime() >= periodStart.getTime() &&
                      el.end.getTime() <= periodEnd.getTime()) ||
                    (el.start.getTime() >= periodStart.getTime() &&
                      el.start.getTime() <= periodEnd.getTime()))
              )
            ) {
              continue;
            }
            if (now < periodEnd.getTime()) {
              events.push({
                name: "frei",
                category: room.description,
                start: periodStart,
                end: periodEnd,
                date: day,
                period: period,
                room: room,
                timed: true,
                color: "success",
                reservable: true,
              });
            }
          }
        }
      }
      this.events = events;
      this.loading = false;
    },
    async clickEvent({ event }) {
      if (event.reservable) {
        const description = await this.$root.prompt({
          message: "Soll dieser Raum reserviert werden?",
          label: "Reservationsgrund",
        });
        if (description) {
          await this.apiPost({
            resource: "register/reservation",
            data: {
              reservedFor: this.$_profilePerson.id,
              room: event.room,
              startDate: event.date,
              startTime: event.period.startTime,
              endDate: event.date,
              endTime: event.period.endTime,
              description: description,
            },
          });
          this.fetchReservations();
        }
      }
      if (event.deletable) {
        if (
          await this.$root.confirm({
            message: "Soll diese Reservation gelöscht werden?",
            color: "danger",
            icon: "mdi-trash-can",
          })
        ) {
          await this.apiDelete({
            resource: "register/reservation",
            id: event.id,
          });
          this.fetchReservations();
        }
      }
    },
    async fetchData() {
      this.loading = true;
      const allRooms = await this.apiList({
        resource: "common/room",
      });
      this.rooms = allRooms.filter((el) => el.reservable);
      this.loading = false;
    },
  },
  async created() {
    await this.fetchData();
    if (this.$route.query.rooms) {
      this.selectedRooms = this.rooms.filter((el) =>
        this.$route.query.rooms.includes(el.code)
      );
    } else if (window.localStorage.getItem("roomReservationRooms")) {
      const roomCodes = JSON.parse(
        window.localStorage.getItem("roomReservationRooms")
      );
      this.selectedRooms = this.rooms.filter((el) =>
        roomCodes.includes(el.code)
      );
    }
  },
  mounted() {
    if (window.localStorage.getItem("roomReservationDate")) {
      this.focus = window.localStorage.getItem("roomReservationDate");
    } else {
      this.setToday();
    }
  },
});
</script>
